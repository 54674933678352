import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import KJRDev from './js/KJRDev';
// import Sobre from './js/Sobre';
// import TodosServicos from './js/TodosServicos.jsx';
import './global.css';
import './css/style.css';


const App = () => {
    return (
        <Router>
            <Routes>
            <Route path="/" element={<KJRDev />} />
            {/* <Route path="/sobre" element={<Sobre />} /> */}
            {/* <Route path="/todosServicos" element={<TodosServicos />} /> */}
            </Routes>
        </Router>
    );
};

export default App;
import React from 'react';
import '../css/Depoimentos.css';

const depoimentosData = [
  {
    company: 'Pastelaria Maza',
    logo: '/images/logoMaza.png', 
    feedback: 'Sistema maravilhoso. Fiz o pedido para um sistema de Gerar Comandas para meu restaurante. Me entregaram além do que eu imaginava, tanto da parte visual do aplicativo como da usabilidade do aplicativo.',
  },
  {
    company: 'Informais',
    logo: '/images/informais_1.png',
    feedback: 'Entrei em contato através de indicação, e fechamos para a criação de uma landPage e trafégo Pago, consegui atingir mais clientes na região através deles.',
  },
  {
    company: 'FernandoCar',
    logo: '/images/logoprov.png',
    feedback: 'Precisava de um sistema de Ponto eletronico, foi me entregue em um tempo muito rapido sem contar que a funcionalide do sistema é facil para os funcionários e para o departamento que toma conta de visualizar as marcações.',
  },
  {
    company: 'BellaCor',
    logo: '/images/bella-cor-logo.png',
    feedback: 'Nosso site tinha algumas alterações e melhorias para serem feitos, com a KJR as melhorias foram entregues no prazo e com uma qualidade excepcional. Com isso temos parcerias com eles para cuidar de nosso site. ',
  },
];

const Depoimentos = () => {
  return (
    <div className="depoimentos">

        <div className="textDepoimentos">
                <p>Depoimentos</p>
            </div>
                <div className="depoimentos-container">
                {depoimentosData.map((depoimento, index) => (
                    <div key={index} className="depoimento">
                    <div className="depoimento-logo-container">
                        <img src={depoimento.logo} alt={`${depoimento.company} logo`} className="depoimento-logo" />
                    </div>
                    <p className="depoimento-company">{depoimento.company}</p>
                    <p className="depoimento-feedback">{depoimento.feedback}</p>
                    </div>
                ))}
                </div>
      </div>
  );
};

export default Depoimentos;

import React from 'react';
import '../css/Servicos.css';
import styles from '../css/TodosServicos.module.css'; 
import { useInView } from 'react-intersection-observer';

const Servicos = () => {
    const { ref, inView } = useInView({
        triggerOnce: false, 
        threshold: 0.1
    });

    return (
        <div className="servicos" ref={ref}>
            <div className="textServicos">
                <p>Serviços</p>
            </div>
            <div className={`${styles.services} ${inView ? styles.visible : styles.hidden}`}>
                <div className={`${styles.service} ${inView ? styles.show : styles.hide}`}>
                    <div className={styles.serviceImage1}>
                        <img src="images/imagem3.jpg" alt="Imagem representando Sites" />
                    </div>
                    <div className={styles.serviceContent}>
                        <h2 className={styles.serviceTitle}>Sites</h2>
                        <p className={styles.serviceDescription}>Realizamos a criação, manutenção, limpeza e reparação no código de diversos tipos de sites, garantindo uma experiência de usuário excepcional e funcionalidades de ponta.</p>
                    </div>
                </div>
          
                <div className={`${styles.service} ${inView ? styles.show : styles.hide}`}>
                    <div className={styles.serviceContent}>
                        <h2 className={styles.serviceTitle}>Aplicação Web</h2>
                        <p className={styles.serviceDescription}>Oferecemos serviços especializados na criação e manutenção de aplicações web personalizadas, projetadas para funcionar perfeitamente em navegadores padrão. Desde aplicativos internos de empresas até plataformas de comércio eletrônico avançadas.</p>
                    </div>
                    <div className={styles.serviceImage2}>
                        <img src="images/imagem2.jpg" alt="Imagem representando Aplicações Web" />
                    </div>
                </div>
          
                <div className={`${styles.service} ${inView ? styles.show : styles.hide}`}>
                    <div className={styles.serviceImage3}>
                        <img src="images/imagem1.jpg" alt="Imagem representando Muito Mais" />
                    </div>
                    <div className={styles.serviceContent}>
                        <h2 className={styles.serviceTitle}>Muito Mais</h2>
                        <p className={styles.serviceDescription}>Oferecemos uma variedade de soluções para atender às necessidades tecnológicas de sua empresa. Isso inclui serviços de helpdesk para oferecer suporte técnico rápido e eficiente, criação de Dashboards e relatórios personalizados utilizando ferramentas como PowerBI, e palestras sobre metodologias ágeis como Scrum para capacitar sua equipe e impulsionar a eficiência e a colaboração em seus projetos.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Servicos;
